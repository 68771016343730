import React from 'react';
import ReactDOM from 'react-dom/client';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import $ from 'jquery';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';



function isObject(obj){
    return obj !== undefined && obj !== null && obj.constructor == Object;
}

function jsonSort(json, sortType){

    if(!isObject(json)){
        return json;
    }

    let keys = Object.keys(json);

    var newJSON = {};

    if(sortType=='acs'){
        keys.sort().map(key => {
            var value = json[key];
            if(isObject(json[key])){
                value = jsonSort(json[key], sortType);
            }
            newJSON[key] = value;
        });
    }

    if(sortType=='desc'){
        keys.sort().reverse().map(key => {
            var value = json[key];
            if(isObject(json[key])){
                value = jsonSort(json[key], sortType);
            }
            newJSON[key] = value;
        });
    }
    return newJSON;

}

let firstText = '1';
let secondText = '2';
const contentMap = new Map();

contentMap.set(firstText, "");
contentMap.set(firstText, "");


class GridControl extends React.Component {
    constructor(props) {
        super(props);
        this.number=props.number;
        this.state = {
            error: false,
            helperText: "",
            message: "",
            showElem: false,
            diffArray: new Array()
        }
    }
    //格式化JSON
    formatJSONClickHandler(id){
        var element = $("#TextField"+id);
        let text = element.val();
        if (text!=null && text.length>0 && isJsonString(text)){

            this.setState({error:false});
            this.setState({helperText:""});

            let result = JSON.stringify(JSON.parse(text), null, 4);
            this.setState({message:result});

            contentMap.set(id, result);
        }else {
            this.setState({error:true});
            this.setState({helperText:"Incorrect JSON."});
        }
    }
    //升序排序JSON
    ascOrDescSort(id, sortType){
        var element = $("#TextField"+id);
        let text = element.val();
        if (text!=null && text.length>0 && isJsonString(text)){

            this.setState({error:false});
            this.setState({helperText:""});

            let result = JSON.stringify(jsonSort(JSON.parse(text),sortType), null, 4);
            this.setState({message:result});

            contentMap.set(id, result);
        }else {
            this.setState({error:true});
            this.setState({helperText:"Incorrect JSON."});
        }
    }

    clearContent(id){
        var element = $("#TextField"+id);
        element.val("");

        contentMap.set(id, "");
    }

    render() {
        return (
            <Grid xs={5.5}>
                <Grid xs={12}>
                    <Stack spacing={2} direction="row">
                        <ButtonGroups variant="contained"
                                      value={'JSON格式化'}
                                      controlText={this.number}
                                      color="success"
                                      onClick={()=>this.formatJSONClickHandler(this.number)}
                        />
                        <ButtonGroups variant="contained"
                                      value={'JSON升序排序'}
                                      controlText={this.number}
                                      onClick={()=>this.ascOrDescSort(this.number, 'acs')}
                        />
                        <ButtonGroups variant="contained"
                                      value={'JSON降序排序'}
                                      controlText={this.number}
                                      onClick={()=>this.ascOrDescSort(this.number, 'desc')}
                        />
                        <ButtonGroups variant="outlined" value={'清空'} controlText={this.number}
                                      startIcon={<DeleteIcon />}
                                      onClick={()=>this.clearContent(this.number)}
                        />
                    </Stack>
                </Grid>
                <Grid xs={12}>
                    <Box component="form" autoComplete="off">
                        <div>
                            <TextField
                                error={this.state.error}
                                id={'TextField'+this.number}
                                label={'JSON-'+this.number}
                                placeholder="please input your json"
                                multiline
                                fullWidth
                                helperText={this.state.helperText}
                                value={this.props.value}

                            />

                        </div>
                    </Box>
                </Grid>

                <Grid xs={12}>
                    <SyntaxHighlighter
                        id={'SyntaxHighlighter'+this.number}
                        language="json"
                        style={docco}
                        wrapLines={true}
                        showLineNumbers={true}
                        lineProps={(lineNumber) => {
                            const style = { display: "block", width: "fit-content" };
                            if (this.state.diffArray.includes(lineNumber)) {
                                style.backgroundColor = "#FB2658";
                            }
                            return { style };
                        }}
                        className={"syntax-highlighter"}

                    >
                        {this.state.message}
                    </SyntaxHighlighter>
                </Grid>

            </Grid>
        );
    }
}


function compareContent(){
    contentMap.get(firstText);
    contentMap.get(secondText);
    debugger;
}


function Background() {

    let allText = '0';

    const compareButtonGroups = <ButtonGroups
        variant="contained"
        value={'比较'}
        controlText={allText}
        color="success"
        onClick={()=>compareContent(firstText, secondText)}
    />;


    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <GridControl number={firstText}></GridControl>

                {/*all*/}
                <Grid xs={1}>
                    {/*<Stack spacing={2} direction="column"*/}
                    {/*       justifyContent="right"*/}
                    {/*       alignItems="center">*/}
                    {/*    <ButtonGroups hidden  controlText={0}/>*/}
                    {/*    {compareButtonGroups}*/}
                    {/*</Stack>*/}
                </Grid>

                <GridControl number={secondText}></GridControl>

            </Grid>
        </Box>
    );
}

function ButtonGroups(props){
    return (
        <Button
            variant={props.variant}
            color={props.color}
            startIcon={props.startIcon}
            onClick={props.onClick}
        >
            {props.value}
        </Button>
    );
}

//common.js utils

function isJsonString(str) {
    if (typeof str == 'string') {
        try {
            if (typeof JSON.parse(str) == "object") {
                return true;
            }
        } catch (e) {
            return false;
        }
    }
    return false;
}


//------------------------------------------------------------------------
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Background />);